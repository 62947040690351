export default function(datestr) {
    let tstmp = Date.parse(datestr);
    if (isNaN(tstmp)) {
        return datestr;
    }

    let d = new Date(tstmp);

    return d.toLocaleDateString("de-DE", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    });
}
