import axios from "axios";

let ax = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true
});
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";

ax.interceptors.request.use(function(config) {
    return config;
});

export default ax;
