<template>
    <div>
        <div class="box">
            <h2>Aktuell aktive Versionen</h2>
            <div class="body">
                <table>
                    <thead>
                        <tr>
                            <th>Umgebung</th>
                            <th>Tag</th>
                            <th>Version</th>
                            <th>Zeitpunkt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="version in environmentVersions" v-bind:key="version.env.env">
                            <td>{{ version.env.label }}</td>
                            <td>{{ version.version.label }}</td>
                            <td>{{ version.version.id }}</td>
                            <td>{{ version.deployedAt | formatTime }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="box">
            <h2 class="with-btn">
                Versionen
                <button @click="createVersion()" type="button" class="secondary size-m"><i class="fal fa-plus"></i> Version erstellen</button>
            </h2>
            <div class="body">
                <table>
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Tag</th>
                            <th>Zeitpunkt</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="version in releasedVersions" v-bind:key="version.version">
                            <td>{{ version.id }}</td>
                            <td>{{ version.label }}</td>
                            <td>{{ version.time | formatTime }}</td>
                            <td>
                                <div class="form-group form-float form-select no-error">
                                    <select v-model="version.env" :id="'deploy_' + version.id">
                                        <option value=""></option>
                                        <option v-for="env in environmentVersions" v-bind:key="env.env.env" v-bind:value="env.env.env">{{ env.env.label }}</option>
                                    </select>
                                    <label :for="'deploy_' + version.id">Umgebung</label>
                                </div>
                            </td>
                            <td>
                                <button @click="deploy(version)" title="Deploy" type="button" class="primary size-l"><i class="fad fa-chart-network"></i></button>
                            </td>
                            <td><i @click="removeVersion(version.id)" class="delete fal fa-trash-alt"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            environmentVersions: [],
            releasedVersions: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        performDeploy(versionId, envId) {
            this.$api.put("/deploy", { version: versionId, env: envId }).then(
                () => {
                    this.$snotify.success("Deployment abgeschlossen");
                    this.fetchData();
                },
                () => {
                    this.$snotify.error("Deployment fehlgeschlagen");
                }
            );
        },
        deploy(version) {
            this.$snotify.confirm("Version ausrollen?", "Letzte Möglichkeit", {
                closeOnClick: false,
                position: "centerCenter",
                buttons: [
                    {
                        text: "Ja",
                        action: toast => {
                            this.performDeploy(version.id, version.env);
                            this.$snotify.remove(toast.id);
                        }
                    },
                    {
                        text: "Nein"
                    }
                ]
            });
        },
        performDelete(id) {
            this.$api.delete("/deploy/version/" + id).then(
                () => {
                    this.$snotify.success("Version gelöscht");
                    this.fetchData();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]) {
                        this.$snotify.error(error.response.data.errors[0].human);
                    } else {
                        this.$snotify.error("Fehler beim löschen der Version");
                    }
                }
            );
        },
        removeVersion(id) {
            this.$snotify.confirm("Version dauerhaft löschen?", "Letzte Möglichkeit", {
                closeOnClick: false,
                position: "centerCenter",
                buttons: [
                    {
                        text: "Ja",
                        action: toast => {
                            this.performDelete(id);
                            this.$snotify.remove(toast.id);
                        }
                    },
                    {
                        text: "Nein"
                    }
                ]
            });
        },
        noAction(toast) {
            this.$snotify.remove(toast.id);
        },
        performCreate(toast) {
            this.$api.post("/deploy/version", { label: toast.value }).then(
                () => {
                    this.$snotify.success("Version erstellt");
                    this.fetchData();
                    this.noAction(toast);
                },
                () => {
                    this.$snotify.error("Fehler beim erstellen der Version");
                    this.noAction(toast);
                }
            );
        },
        createVersion() {
            this.$snotify.prompt("", "Version erstellen", {
                buttons: [
                    { text: "Save", action: this.performCreate, bold: true },
                    { text: "Cancel", action: this.noAction }
                ],
                placeholder: "Tag",
                position: "centerCenter"
            });
        },
        fetchData() {
            this.$api.get("/env/versions").then(
                response => {
                    this.environmentVersions = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der aktiven Versionen");
                }
            );

            this.$api.get("/deploy/versions").then(
                response => {
                    this.releasedVersions = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der verfügbaren Versionen");
                }
            );
        }
    }
};
</script>

<style lang="scss" scoped>
h2.with-btn {
    display: flex;
    position: relative;

    button {
        position: absolute;
        right: 0.7rem;
        top: 0.7rem;
    }
}
</style>
