import Vue from "vue";
import Vuex from "vuex";
import VueCookie from "vue-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentJWT: VueCookie.get("data_token") || ""
    },

    getters: {
        jwt: state => state.currentJWT,
        jwtData: (state, getters) => {
            if (state.currentJWT) {
                try {
                    return JSON.parse(atob(getters.jwt.split(".")[1]));
                } catch (e) {
                    return {};
                }
            }
            return {};
        },
        jwtSubject: (state, getters) => (getters.jwtData ? getters.jwtData.sub : null),
        jwtIssuer: (state, getters) => (getters.jwtData ? getters.jwtData.iss : null),
        jwtExpires: (state, getters) => (getters.jwtData ? getters.jwtData.exp : null)
    }
});
