import Vue from "vue";
import Images from "@altimate-net/vue-components/src/assets/images";
import Paging from "@altimate-net/vue-components/src/components/widgets/Paging";
import PublicLayout from "@altimate-net/vue-components/src/layouts/Public";
import Dropdown from "@altimate-net/vue-components/src/components/widgets/Dropdown";
import Popup from "@altimate-net/vue-components/src/components/widgets/Popup";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Snotify from "vue-snotify";
import VeeValidate from "vee-validate";
import API from "./service/api";
import VueCookie from "vue-cookie";
import ProtectedLayout from "./layouts/Protected";
import formatTime from "./filter/formatTime";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Vue.config.productionTip = false;
Vue.prototype.$api = API;

Vue.use(VueCookie);

Vue.use(Snotify);
Vue.use(VeeValidate, {
    classes: true
});
Vue.component("public-layout", PublicLayout);
Vue.component("dropdown", Dropdown);
Vue.component("paging", Paging);
Vue.component("popup", Popup);
Vue.component("protected-layout", ProtectedLayout);
Vue.filter("formatTime", formatTime);

Images.logo = require("./assets/img/logo.svg");
Images.logoWhite = require("./assets/img/logo_white.svg");
Vue.prototype.$imgs = Images;

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })]
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
