<template>
    <div class="internal" :class="{ showNav: showNav && toggled, hideNav: !showNav && toggled }">
        <header>
            <div class="topbar">
                <i
                    @click="
                        showNav = false;
                        toggled = true;
                    "
                    class="nav-close fal fa-arrow-circle-left"
                ></i>
                <i
                    @click="
                        showNav = true;
                        toggled = true;
                    "
                    class="nav-open fal fa-bars"
                ></i>
                <slot name="topbar"></slot>
                Konfiguration für KreditConnect
            </div>
        </header>
        <div
            @click="
                showNav = false;
                toggled = true;
            "
            class="navbg"
        ></div>
        <nav>
            <div class="logo">
                <img :src="$imgs.logoWhite" />
                <slot name="title"></slot>
            </div>
            <div class="nav">
                <div class="profile">
                    <i class="avatar fal fa-user"></i>
                    <dropdown>
                        <template slot="trigger"><i class="menu-button fal fa-chevron-down"></i></template>
                        <template slot="menu">
                            <ul>
                                <li class="divider"></li>
                                <li>
                                    <a href="#" @click.prevent="logout()"><i class="fal fa-lock"></i> Logout</a>
                                </li>
                            </ul>
                        </template>
                    </dropdown>
                    <br />
                    {{ jwtData.firstname }} {{ jwtData.lastname }}
                </div>
                <slot name="nav"></slot>
            </div>
        </nav>
        <main>
            <slot></slot>
        </main>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            toggled: false,
            showNav: true,
            interval: null
        };
    },
    computed: {
        ...mapGetters(["jwtData"])
    },
    methods: {
        logout() {
            this.$api.post("/auth/logout").then(
                () => {
                    this.$router.push({ name: "logged_out" });
                },
                () => {
                    // Logout failed
                    this.$snotify.error("Logout failed");
                }
            );
        }
    }
};
</script>
